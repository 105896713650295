import React from "react"

import Layout from "../components/layout";
import SEO from "../components/seo";

import ProgramGeneralInfo from '../components/programGeneralInfo';
import ProgramCostTable from "../components/programCostTable";
import Textbooks from '../components/textbooks';

import fullSpecialistHero from '../images/full-specialist-hero.jpg';

const content = {
  title: "Full Specialist Program",
  paragraphs: ["The Full Specialist Program (FUSP) is taught in English. The Full Specialist Program includes study in manicuring, makeup & facials, hand & arm massage or bleach & shampooing. Completion of this course prepares graduates to become a licensed full specialist.", "The 600 Hour Full Specialist Program is designed to instruct and train students in sterilization and sanitation, the theory and practical skills of manicure and skin care. The program prepares students with the foundation necessary to become Certified Full Specialist. Upon completion of the Full Specialist program, the student will receive a diploma, will apply to State of Florida for licensing and obtain entry-level employment in the nail and skin care industry."],
  images: {
    heroImage: fullSpecialistHero
  }
}

const costsObj = {
  title: "Full Specialist",
  tuition: "$6,050.00",
  appFees: "$50.00",
  regFees: "$100.00",
  supplies: "$1,550.00",
  stateFees: "$180.00",
  hivFees: "$20.00",
  totalCost: "$7,900.00",
  totalHours: "600 hours"
}

const textBooks = [
    // {
    //     bookName: "Milady’s Standard Esthetics 12th Edition - MindTap Digital Learning Solution; ISBN-13: 9781337095150 - $269.95",
    //     bookLink: "https://www.google.com/search?q=Milady%E2%80%99s+Standard+Esthetics+12th+Edition+-+MindTap+Digital+Learning+Solution%3B+ISBN-13%3A+9781337095150&oq=Milady%E2%80%99s+Standard+Esthetics+12th+Edition+-+MindTap+Digital+Learning+Solution%3B+ISBN-13%3A+9781337095150&aqs=chrome..69i57.893j0j7&sourceid=chrome&ie=UTF-8"
    // },
    // {
    //     bookName: "Milady’s Standard Nail Technology 8th Edition MindTap Digital Learning; ISBN: 9781337786669 - $269.95",
    //     bookLink: "https://www.google.com/search?q=Milady%E2%80%99s+Standard+Nail+Technology+8th+Edition+MindTap+Digital+Learning%3B+ISBN%3A+9781337786669&oq=Milady%E2%80%99s+Standard+Nail+Technology+8th+Edition+MindTap+Digital+Learning%3B+ISBN%3A+9781337786669&aqs=chrome.0.69i59.217j0j7&sourceid=chrome&ie=UTF-8"
    // },
    {
      bookName: "Milady’s CIMA for Full Specialty: Foundations + Esthetics (12th Edition)+ Nails (8th Edition) ISBN: 9780357875049 - $449.95",
      bookLink: "https://www.google.com/search?q=Milady%E2%80%99s+CIMA+for+Full+Specialty%3A+Foundations+%2B+Esthetics+(12th+Edition)%2B+Nails+(8th+Edition)+ISBN%3A+9780357875049&oq=Milady%E2%80%99s+CIMA+for+Full+Specialty%3A+Foundations+%2B+Esthetics+(12th+Edition)%2B+Nails+(8th+Edition)+ISBN%3A+9780357875049&aqs=chrome..69i57.939j0j1&sourceid=chrome&ie=UTF-8"
    },
    {
      bookName: "1 Samsung Chrome Book - $300.00",
      bookLink: "javascript:void(0)"
    },
    {
        bookName: "Onetonline.org - SOC CODE: 39-5092",
        bookLink: "https://www.onetonline.org/link/summary/39-5092.00"
    },
    {
        bookName: "Onetonline.org - SOC CODE: 39-5094",
        bookLink: "https://www.onetonline.org/link/summary/39-5094.00"
    },
    {
      bookName: "CIP CODES: 12.0410 12.0414",
      bookLink: "javascript:void(0)"
    }
]

const SkinCareProgram = () => (
    <Layout>
      <SEO title="Full Specialist Program" />
      <br/>
      <ProgramGeneralInfo content={content} />
      <ProgramCostTable costs={costsObj} />
      <Textbooks books={textBooks} />
    </Layout>
  )
  
  export default SkinCareProgram